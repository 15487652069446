import React from 'react';
import '../styles.css';

function SyncCustomers({ isSyncCustomers }) {
  if (!isSyncCustomers) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999
    }}>
      <div style={{
        textAlign: 'center',
        color: 'white'
      }}>
        <div className="loading-bars">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div>Sincronizando<span className="dots"></span></div>
      </div>
    </div>
  );
}

export default SyncCustomers;
