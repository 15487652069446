import React, { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { Grid, TextField, Checkbox, IconButton, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import Autocomplete from '@mui/material/Autocomplete';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import DataTable from 'examples/Tables/DataTable';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import config from 'layouts/utils/config';
import axios from 'axios';
import auth from 'layouts/utils/auth';
import './styles.css';
import FilterTickets from './components/FilterTickets';
import FilterDialog from './components/FilterDialog';
import ConfigurationSendNotification from './components/ConfigurationSendNotification';
import SyncCustomers from './components/SyncCustomers';
import Card from '@mui/material/Card';
import SettingsIcon from '@mui/icons-material/Settings';

function Notificar() {
  const [clientsData, setClientsData] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState([]);
  const [payloadFilter, setPayloadFilter] = useState({});
  const [ticketsData, setTicketsData] = useState([]);
  const [countTickets, setCountTickets] = useState(0);
  const [selectedTickets, setSelectedTickets] = useState({});
  const [cpfClient, setCpfClient] = useState('');
  const [codClient, setCodClient] = useState('');
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [valueProgress, setProgress] = useState(0);
  const [maxProgress, setMaxProgress] = useState(100);
  const [isSending, setIsSending] = useState(false);
  const [templateEmail, setTemplateEmail] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('')
  const [configDialogOpen, setConfigDialogOpen] = useState(false);
  const [selectedSendBy, setSelectedSendBy] = useState('');
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [selectedTemplateWhatsApp, setSelectedTemplateWhatsApp] = useState('');
  const [selectedTemplateEmail, setSelectedTemplateEmail] = useState('');
  const [templateWhatsapp, setTemplateWhatsapp] = useState('');
  const [orderVariables, setOrderVariables] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [isSyncCustomers, setIsSyncCustomers] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);

  const fetchTemplates = async (type) => {
    try {
      if (type === 'email' || type === 'both') {
        const emailResponse = await axios.get(`${config.base_api_url}/template-message/?type=email`, {
          headers: { Authorization: `Bearer ${auth.getToken()}` },
        });
        setTemplateEmail(emailResponse.data);
      }

      if (type === 'whatsapp' || type === 'both') {
        const whatsappResponse = await axios.get(`${config.base_api_url}/template-message/whatsapp/`, {
          headers: { Authorization: `Bearer ${auth.getToken()}` },
        });
        setTemplateWhatsapp(whatsappResponse.data);
      }
    } catch (error) {
      setAlertTitle("Erro");
      setAlertMessage("Erro ao buscar templates.");
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    fetchTemplates(selectedSendBy);
  }, [selectedSendBy]);

  const sendBy = [
    { value: 'whatsapp', label: 'WhatsApp' },
    { value: 'email', label: 'E-mail' },
    { value: 'both', label: 'Ambos' },
  ];

  const handleConfigDialogOpen = () => {
    setConfigDialogOpen(true);
  }

  const handleConfigDialogClose = () => {
    setConfigDialogOpen(false);
  }

  const handleAutocompleteOpen = (page = 0) => {
    setIsLoadingCustomers(true);
    axios.get(`${config.base_api_url}/customer/`, {
      headers: {
        Authorization: `Bearer ${auth.getToken()}`
      }
    })
      .then((response) => {
        setClientsData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        setAlertMessage("Erro ao buscar os boletos. Por favor, tente novamente.");
        setAlertTitle("Aviso");
        setAlertOpen(true);
      })
      .finally(() => {
        setIsLoadingCustomers(false);
      });
  };

  useEffect(() => {
    handleAutocompleteOpen();
  }, [])

  const syncCustomers = () => {
    setIsSyncCustomers(true);
    axios.post(`${config.base_api_url}/customer/`, {}, {
      headers: {
        Authorization: `Bearer ${auth.getToken()}`
      }
    })
    .then((response) => {
      setAlertTitle("Mensagem");
      setAlertMessage(response.data);
      setAlertOpen(true);
    })
    .catch((error) => {
      setAlertTitle("Erro");
      setAlertMessage("Sincronização falhou.");
      setAlertOpen(true);
    })
    .finally(() => {
      setIsSyncCustomers(false);
    });
  };

  const isAllSelected = ticketsData.length > 0 && Object.keys(selectedTickets).length === ticketsData.length;

  const handleSelectAllTickets = () => {
    let newSelected = {};

    if (Object.keys(selectedTickets).length !== ticketsData.length) {
      ticketsData.forEach(ticket => {
        newSelected[ticket.nome_cliente] = {
          ...ticket,
          contato: Array.isArray(ticket.contato) ? ticket.contato[0] || null : null,
          email: Array.isArray(ticket.email) ? ticket.email[0] || null : null,
          isEditable: true,
        };
      });
    }

    setSelectedTickets(newSelected);
  };

  const handleSelectTicket = (ticket) => {
    const nome_cliente = ticket.nome_cliente;
    const updatedSelected = { ...selectedTickets };

    if (updatedSelected[nome_cliente]) {
      delete updatedSelected[nome_cliente];
    } else {
      updatedSelected[nome_cliente] = {
        ...ticket,
        contato: Array.isArray(ticket.contato) ? ticket.contato[0] || { contato: 'NENHUM', descricao: 'NENHUM' } : { contato: 'NENHUM', descricao: 'NENHUM' },
        email: Array.isArray(ticket.email) ? ticket.email[0] || { email: 'NENHUM', Departamento: 'NENHUM', situacao: 'NENHUM' } : { email: 'NENHUM', Departamento: 'NENHUM', situacao: 'NENHUM' },
        isEditable: true,
      };
    }

    setSelectedTickets(updatedSelected);
  };

  const handleExpandTicket = (nome_cliente) => {
    setExpandedRow(prev => prev === nome_cliente ? null : nome_cliente);
  };

  const handleEditTicket = (nome_cliente, updates) => {
    setSelectedTickets(prevTickets => ({
      ...prevTickets,
      [nome_cliente]: {
        ...prevTickets[nome_cliente],
        ...updates,
      },
    }));
  };

  const ticketsDataTableRows = async () => {
    setIsLoading(true);
    setTicketsData([]);
    try {
      const response = await axios.post(`${config.base_api_url}/customer/tickets/`, payloadFilter, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      });
      if (response.data) {
        setTicketsData(response.data.data || []);
        setCountTickets(response.data.quantidade_boletos || 0);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error.response?.data?.error);
      setAlertMessage(error.response?.data?.detail || error.response?.data?.error || "Erro desconhecido");
      setAlertTitle("Aviso");
      setAlertOpen(true);
      setIsLoading(false);
    }
  };

  const columnsBoletos = [
    { 
      Header: "Código", 
      accessor: "cod_cliente", 
      Cell: ({ value }) => 
      <div 
        style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} 
        title={value}
      >
        {value}
      </div> 
    },
    { 
      Header: "Código de Barras", 
      accessor: "cod_barras", 
      Cell: ({ value }) => 
      <div 
        style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} 
        title={value}
      >
        {value}
      </div> 
    },
    { 
      Header: "Link", 
      accessor: "link", 
      Cell: ({ value }) => 
      <a 
        href={value} 
        target="_blank" 
        rel="noopener noreferrer" 
        style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block' }} 
        title={value}
      >
        {value}
      </a> 
    },
    { 
      Header: "Parcela", 
      accessor: "parcela", 
      Cell: ({ value }) => 
      <div 
        style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} 
        title={value}
      >
        {value}
      </div> 
    },
    { 
      Header: "Valor", 
      accessor: "valor", 
      Cell: ({ value }) => 
      <div 
        style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} 
        title={value}
      >
        {value}
      </div> 
    },
    { 
      Header: "Emissão", 
      accessor: "data_emissao", 
      Cell: ({ value }) => 
      <div 
        style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} 
        title={value}
      >
        {value}
      </div> 
    },
    { 
      Header: "Vencimento", 
      accessor: "data_vencimento", 
      Cell: ({ value }) => 
      <div 
        style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} 
        title={value}
      >
        {value}
      </div> 
    },
  ];

  const renderGroupedTickets = () => {
    if (!Array.isArray(ticketsData) || ticketsData.length === 0) {
      return null;
    }

    return ticketsData.map((cliente, index) => (
        <Dialog 
          key={`${cliente.cpf_cliente}-${cliente.nome_cliente}-${index}`}
          open={expandedRow === cliente.nome_cliente} onClose={() => handleExpandTicket(cliente.nome_cliente)}
          maxWidth="lg"
        >
          <DialogTitle>
            <MDBox
              mx={0.2}
              py={1}
              px={1}
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Boletos de {cliente.nome_cliente}
              </MDTypography>
            </MDBox>
          </DialogTitle>
          <DialogContent>
            <DataTable
              table={{
                columns: columnsBoletos,
                rows: cliente.boletos || []
              }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
              pagination={false}
            />
            <MDBox px={1} py={1} display="flex" justifyContent="flex-end" alignItems="center">
              <p style={{ fontSize: 13, marginRight: 10, fontWeight: "bold", padding: 4, color: "#80859E" }}>QUANTIDADE</p>
              <p style={{ fontSize: 13, border: "1px solid #E1E3E8", backgroundColor: "#F0F2F5", padding: 8, borderRadius: 5, fontWeight: "bold", color: "#80859E" }}>{cliente.boletos.length}</p>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={() => handleExpandTicket(cliente.nome_cliente)} color="error" fullWidth>
              Fechar
            </MDButton>
          </DialogActions>
        </Dialog>
    ));
  };

  const formatCpf = (cpf) => {
    return cpf.replace(/[^\d]/g, '');
  };

  const handleAutocompleteChange = (event, value) => {
    const uniqueClients = value.filter((client, index, self) =>
      index === self.findIndex((c) => c.cpf_cliente === client.cpf_cliente)
    );

    const newPayloadFilter = {
      ...payloadFilter,
      infos_clientes: uniqueClients.map((client) => ({
        cpf: formatCpf(client.cpf_cliente),
        nome: client.nome_cliente,
        contato: client.contato_cliente,
        email: client.email
      })),
    };

    setAutocompleteValue(uniqueClients);
    setPayloadFilter(newPayloadFilter);
    setTicketsData([]);
  };

  const handleDateChange = (key, date) => {
    setPayloadFilter((prevFilter) => ({
      ...prevFilter,
      [key]: date,
    }));
    setTicketsData([]); 
  };

  const handleFilterDialogOpen = () => {
    setFilterDialogOpen(true);
  };

  const handleFilterDialogClose = () => {
    setFilterDialogOpen(false);
  };

  const handleApplyFilters = () => {
    const cpfValues = cpfClient.split(',').map(value => value.trim()).join(',');
  
    setPayloadFilter({
      cpf_cliente: cpfValues,
      data_emissao_inicial: payloadFilter.data_emissao_inicial || '',
      data_emissao_final: payloadFilter.data_emissao_final || '',
      data_vencimento_inicial: payloadFilter.data_vencimento_inicial || '',
      data_vencimento_final: payloadFilter.data_vencimento_final || '',
    });
  
    setFilterDialogOpen(false);
    setTicketsData([]); 
  };

  const handleSendNotifications = async () => {
    if (!selectedSendBy) {
      setAlertTitle("Aviso");
      setAlertMessage("Selecione um meio de envio.");
      setAlertOpen(true);
      return;
    } else if (Object.keys(selectedTickets).length === 0) {
      setAlertTitle("Aviso");
      setAlertMessage("Selecione pelo menos um boleto.");
      setAlertOpen(true);
      return;
    } else {
      if ((selectedSendBy === 'whatsapp' || selectedSendBy === 'both') && !selectedTemplateWhatsApp) {
        setAlertTitle("Aviso");
        setAlertMessage("Selecione um template de WhatsApp.");
        setAlertOpen(true);
        return;
      }
      if ((selectedSendBy === 'email' || selectedSendBy === 'both') && !selectedTemplateEmail) {
        setAlertTitle("Aviso");
        setAlertMessage("Selecione um template de Email.");
        setAlertOpen(true);
        return;
      }
    }

    const source = axios.CancelToken.source();
    setCancelTokenSource(source);
    setIsSending(true);
    setProgress(0);
    setMaxProgress(Object.keys(selectedTickets).length);
    const dateTimeStart = new Date().toLocaleString('sv-SE', { timeZone: 'America/Sao_Paulo' }).replace(' ', 'T');
    let report = [];
    
    for (const ticket of Object.values(selectedTickets)) {
      try {
        const payload = {
          'nome_cliente': ticket.nome_cliente,
          'contato_cliente': ticket.contato?.contato,
          'email': ticket.email?.email,
          'boletos': ticket.boletos,
          'type': selectedSendBy,
          'template_whatsapp_id': selectedTemplateWhatsApp ? selectedTemplateWhatsApp.id : false,
          'template_whatsapp_tag': selectedTemplateWhatsApp ? selectedTemplateWhatsApp.tag : false,
          'template_whatsapp_params': orderVariables || '',
          'template_email': selectedTemplateEmail ? selectedTemplateEmail.message : false,
        };

        const response = await axios.post(`${config.base_api_url}/customer/send/`, payload, {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`
          },
          cancelToken: source.token,
        });
        report.push(response.data.report_id);
        setProgress(prev => prev + 1);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Operação cancelada:', error.message);
        } else {
          setAlertTitle("Erro");
          setAlertMessage(`Erro ao enviar boleto: ${ticket.nome_cliente}`);
          setAlertOpen(true);
        }
      }
    }
  
    const dateTimeEnd = new Date().toLocaleString('sv-SE', { timeZone: 'America/Sao_Paulo' }).replace(' ', 'T');

    try {
      const reportData = {
        report: report,
        date_time_start: dateTimeStart,
        date_time_end: dateTimeEnd,
        type: selectedSendBy
      };

      await axios.post(`${config.base_api_url}/reports/session/`, reportData, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        },
      });

      setIsSending(false);
      setAlertTitle("Sucesso");
      setAlertMessage("Boletos enviados com sucesso.");
      setAlertOpen(true);
    } catch (error) {
      setAlertTitle("Erro");
      setAlertMessage("Erro ao registrar sessão.");
      setAlertOpen(true);
    }
    setSelectedTickets({});
  };

  const handleDeleteTicket = (nome_cliente) => {
    const updatedTickets = ticketsData.filter(ticket => ticket.nome_cliente !== nome_cliente);
    setTicketsData(updatedTickets);

    const updatedSelectedTickets = { ...selectedTickets };
    delete updatedSelectedTickets[nome_cliente];
    setSelectedTickets(updatedSelectedTickets);
  };

  const handleDeselectAllTickets = () => {
    setSelectedTickets({});
  };

  const handleDeleteAllSelected = () => {
    const selectedCodBoletos = Object.keys(selectedTickets);
    const updatedTickets = ticketsData.filter(ticket => !selectedCodBoletos.includes(ticket.nome_cliente));

    setTicketsData(updatedTickets);
    setSelectedTickets({});
  };

  const handleSendByChange = (event, value) => {
    if (value) {
      setSelectedSendBy(value.value);
      if (value.value === 'whatsapp' || value.value === 'both') {
        fetchTemplates('whatsapp');
      }
    } else {
      setSelectedSendBy('');
    }
  };

  function getSendIcon() {
    if (selectedSendBy === 'whatsapp') {
      return <WhatsAppIcon />;
    } else if (selectedSendBy === 'email') {
      return <EmailIcon />;
    } else if (selectedSendBy === 'both') {
      return (
        <Box display="flex">
          <WhatsAppIcon />
          <EmailIcon />
        </Box>
      );
    }
    return null;
  }

  const handleCancel = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Operação cancelada pelo usuário.');
      setIsSending(false);
    }
  };

  const handleOrderVariables = (value) => {
    setOrderVariables(value);
  }

  const handleEmailChange = (nome_cliente, newEmail) => {
    const updatedSelectedTickets = { ...selectedTickets };
    updatedSelectedTickets[nome_cliente] = {
      ...updatedSelectedTickets[nome_cliente],
      email: newEmail,
    };
    setSelectedTickets(updatedSelectedTickets);
  };

  const handleContactChange = (nome_cliente, newContato) => {
    const updatedSelectedTickets = { ...selectedTickets };
    updatedSelectedTickets[nome_cliente] = {
      ...updatedSelectedTickets[nome_cliente],
      contato: newContato,
    };
    setSelectedTickets(updatedSelectedTickets);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <DashboardLayout>
        <MDBox>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FilterTickets
                clientsData={clientsData}
                autocompleteValue={autocompleteValue}
                handleAutocompleteChange={handleAutocompleteChange}
                handleFilterDialogOpen={handleFilterDialogOpen}
                syncCustomers={syncCustomers}
                isLoadingCustomers={isLoadingCustomers}
                isSyncCustomers={isSyncCustomers}
                handleDateChange={handleDateChange}
                payloadFilter={payloadFilter}
                ticketsDataTableRows={ticketsDataTableRows}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              {
                isLoading ? (
                  <div className="loading-bars">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={1}
                      mt={-1}
                      py={1}
                      px={1}
                      variant="gradient"
                      bgColor="warning"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Tabela de Boletos em Aberto
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={1} className="table-container">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                checked={isAllSelected}
                                indeterminate={Object.keys(selectedTickets).length > 0 && Object.keys(selectedTickets).length < ticketsData.length}
                                onChange={handleSelectAllTickets}
                              />
                            </th>
                            <th>Nome</th>
                            <th>Contatos</th>
                            <th>Emails</th>
                            <th>Excluir</th>
                            <th>Boletos</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ticketsData.map((ticket, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={!!selectedTickets[ticket.nome_cliente]}
                                  onChange={() => handleSelectTicket(ticket)}
                                />
                              </td>
                              <td>{ticket.nome_cliente}</td>
                              <td>
                                <select
                                  value={selectedTickets[ticket.nome_cliente]?.contato?.contato || ''}
                                  onChange={(e) => handleContactChange(ticket.nome_cliente, ticket.contato?.find(contact => contact.contato === e.target.value))}
                                  disabled={!selectedTickets[ticket.nome_cliente]}
                                >
                                  <option value="NENHUM">NENHUM</option>
                                  {Array.isArray(ticket.contato) && ticket.contato.map((contact) => (
                                    <option key={contact.contato} value={contact.contato}>
                                      {contact.contato} - {contact.descricao}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <select
                                  value={selectedTickets[ticket.nome_cliente]?.email?.email || ''}
                                  onChange={(e) => handleEmailChange(ticket.nome_cliente, ticket.email?.find(email => email.email === e.target.value))}
                                  disabled={!selectedTickets[ticket.nome_cliente]}
                                >
                                  <option value="NENHUM">NENHUM</option>
                                  {Array.isArray(ticket.email) && ticket.email.map((email) => (
                                    <option key={email.email} value={email.email}>
                                      {email.email} - {email.Departamento === 'TODOS' ? 'TODOS' : email.Departamento} - {email.situacao}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <IconButton onClick={() => handleDeleteTicket(ticket.nome_cliente)}>
                                  <DeleteIcon />
                                </IconButton>
                              </td>
                              <td>
                                <MDButton onClick={() => handleExpandTicket(ticket.nome_cliente)} color="info">
                                  Ver ({ticket.boletos.length})
                                </MDButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {renderGroupedTickets()}
                    </MDBox>
                    <MDBox px={2} py={2} display="flex" justifyContent="space-between" alignItems="center">
                      <MDBox display="flex" alignItems="center">
                        <IconButton onClick={handleConfigDialogOpen} style={{ marginRight: '10px' }}>
                          <SettingsIcon />
                        </IconButton>
                        <MDButton color="success" startIcon={getSendIcon()} onClick={handleSendNotifications} disabled={isSending}>
                          Enviar Notificação
                        </MDButton>
                        {Object.keys(selectedTickets).length > 0 && (
                          <>
                            <MDButton 
                              color="error" 
                              startIcon={<DeleteIcon />} 
                              onClick={handleDeleteAllSelected}
                              style={{ marginLeft: '10px' }}
                            >
                              Excluir Selecionados
                            </MDButton>
                            <MDButton 
                              color="warning" 
                              onClick={handleDeselectAllTickets}
                              style={{ marginLeft: '10px' }}
                            >
                              Desselecionar Todos
                            </MDButton>
                          </>
                        )}
                      </MDBox>
                      <MDBox px={1} py={1} display="flex" justifyContent="flex-end" alignItems="center">
                        <p style={{ fontSize: 13, marginRight: 10, fontWeight: "bold", padding: 4, color: "#80859E" }}>QUANTIDADE</p>
                        <p style={{ fontSize: 13, border: "1px solid #E1E3E8", backgroundColor: "#F0F2F5", padding: 8, borderRadius: 5, fontWeight: "bold", color: "#80859E" }}>
                          {countTickets}
                        </p>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
                )
              }
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
      
      <FilterDialog
        open={filterDialogOpen}
        onClose={handleFilterDialogClose}
        cpfClient={cpfClient}
        setCpfClient={setCpfClient}
        codClient={codClient}
        setCodClient={setCodClient}
        handleApplyFilters={handleApplyFilters}
      />

      <Dialog open={alertOpen} onClose={() => setAlertOpen(false)}>
        <DialogTitle>{alertTitle}</DialogTitle>
        <DialogContent>{alertMessage}</DialogContent>
        <DialogActions>
          <MDButton onClick={() => setAlertOpen(false)} color="info" fullWidth>Fechar</MDButton>
        </DialogActions>
      </Dialog>
      
      <Dialog open={isSending} PaperProps={{style:{width: '100vh'}}}>
        <DialogTitle>Enviando...</DialogTitle>
        <DialogContent>
          <progress value={valueProgress} max={maxProgress} className="styled-progress" />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCancel} color="error" fullWidth>Cancelar</MDButton>
        </DialogActions>
      </Dialog>

      <ConfigurationSendNotification
        open={configDialogOpen}
        onClose={handleConfigDialogClose}
        sendBy={sendBy}
        selectedSendBy={selectedSendBy}
        handleSendByChange={handleSendByChange}
        templateWhatsapp={templateWhatsapp}
        templateEmail={templateEmail}
        selectedTemplateWhatsApp={selectedTemplateWhatsApp}
        setSelectedTemplateWhatsApp={setSelectedTemplateWhatsApp}
        selectedTemplateEmail={selectedTemplateEmail}
        setSelectedTemplateEmail={setSelectedTemplateEmail}
        orderVariables={orderVariables}
        handleOrderVariables={handleOrderVariables}
      />
      
      <SyncCustomers isSyncCustomers={isSyncCustomers} />
    </LocalizationProvider>
  );
}

export default Notificar;