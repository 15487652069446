import React from "react";
import auth from "layouts/utils/auth";
import config from "layouts/utils/config";
import { Grid, Card, TextField, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import axios from 'axios';

function PoliConfig () {
    const [customer, setCustomer] = React.useState('');
    const [channel, setChannel] = React.useState('');
    const [user, setUser] = React.useState('');
    const [token, setToken] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertTitle, setAlertTitle] = React.useState('');

    const getPoliConfig = async () => {
        try {
            const response = await axios.get(`${config.base_api_url}/settings/poli/`, {
                headers: { Authorization: `Bearer ${auth.getToken()}` },
            });
            setCustomer(response.data.POLI_CUSTOMER || '');
            setChannel(response.data.POLI_CHANNEL || '');
            setUser(response.data.POLI_USER || '');
            setToken(response.data.POLI_TOKEN || '');
        } catch (error) {
            console.error(error);
        }
    }

    React.useEffect(() => {
        getPoliConfig();
    }, []);

    const updatePoliConfig = async () => {
        setIsLoading(true);
        try {
            await axios.patch(`${config.base_api_url}/settings/poli/`, {
                POLI_USER: user,
                POLI_TOKEN: token,
                POLI_CUSTOMER: customer,
                POLI_CHANNEL: channel,
            }, {
                headers: { Authorization: `Bearer ${auth.getToken()}` },
            });
            setAlertTitle("Sucesso");
            setAlertMessage("Configurações de POLI CHAT salvas com sucesso.");
            setAlertOpen(true);
            getPoliConfig();
        } catch (error) {
            setAlertTitle("Erro");
            setAlertMessage("Falha ao salvar configurações de SMTP.");
            setAlertOpen(true);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card style={{ padding: 10, borderRadius: 15 }} sx={{ boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)' }}>
                    <MDBox
                        mx={0.2}
                        mt={-2.2}
                        py={1}
                        px={1}
                        variant="gradient"
                        bgColor="warning"
                        borderRadius="lg"
                        coloredShadow="info"
                        mb={2}
                    >
                        <MDTypography variant="h6" color="white">
                        Configurações de POLI CHAT
                        </MDTypography>
                    </MDBox>
                    <Box sx={{ marginBottom: '20px' }}>
                        <TextField
                            fullWidth
                            label="Usuário"
                            value={user}
                            onChange={(e) => setUser(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '20px' }}>
                        <TextField
                            fullWidth
                            label="Token"
                            type="txt"
                            value={token}
                            onChange={(e) => setToken(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '20px' }}>
                        <TextField
                            fullWidth
                            label="Customer"
                            value={customer}
                            onChange={(e) => setCustomer(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '20px' }}>
                        <TextField
                            fullWidth
                            label="Channel"
                            value={channel}
                            onChange={(e) => setChannel(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ marginTop: '20px' }}>
                        <MDButton
                            variant="contained"
                            color="info"
                            onClick={updatePoliConfig}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Salvando...' : 'Salvar'}
                        </MDButton>
                    </Box>
                </Card>
            </Grid>

            <Dialog open={alertOpen} onClose={() => setAlertOpen(false)}>
                <DialogTitle>{alertTitle}</DialogTitle>
                <DialogContent>{alertMessage}</DialogContent>
                <DialogActions>
                    <MDButton onClick={() => setAlertOpen(false)} color="info" fullWidth>Fechar</MDButton>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default PoliConfig;