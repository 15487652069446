import React from "react";
import { Navigate } from "react-router-dom";
import auth from "layouts/utils/auth";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = auth.getToken();

  return isAuthenticated ? children : <Navigate to="/entrar" />;
};

export default PrivateRoute;

