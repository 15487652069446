import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid } from '@mui/material';
import MDButton from 'components/MDButton';

function FilterDialog({ open, onClose, cpfClient, setCpfClient, codClient, setCodClient, handleApplyFilters }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>Filtros</span>
          <p style={{ fontSize: 12, fontStyle: 'italic', marginLeft: '10px' }}>(Separe os valores por vírgula)</p>
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="CPF/CNPJ Cliente"
          value={cpfClient}
          onChange={(e) => setCpfClient(e.target.value)}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Código Cliente"
          value={codClient}
          onChange={(e) => setCodClient(e.target.value)}
          fullWidth
          margin="dense"
        />
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <MDButton onClick={onClose} color='error' style={{width: "100%"}}>Cancelar</MDButton>
          </Grid>
          <Grid item xs={4}>
            <MDButton onClick={() => {
              setCpfClient('');
              setCodClient('');
            }} color='info' style={{width: "100%"}}>Limpar</MDButton>
          </Grid>
          <Grid item xs={4}>
            <MDButton onClick={handleApplyFilters} color='success' style={{width: "100%"}}>Aplicar</MDButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default FilterDialog;
