import React from 'react';
import { Grid, Card, TextField, Box, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import config from 'layouts/utils/config';
import auth from 'layouts/utils/auth';

function WhatsAppTemplate () {
  const [message, setMessage] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [templates, setTemplates] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [autocompleteKey, setAutocompleteKey] = React.useState(0);
  const [isEditing, setIsEditing] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState('');
  const [alertMessage, setAlertMessage] = React.useState('');

  React.useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${config.base_api_url}/template-message/whatsapp/`, {
        headers: { Authorization: `Bearer ${auth.getToken()}` },
      });
      setTemplates(response.data);
    } catch (error) {
      setAlertTitle('Erro');
      setAlertMessage(error.response?.detail || 'Erro ao buscar templates de WhatsApp');
      setAlertOpen(true);
    }
  };

  const clearInputs = () => {
    setTitle('');
    setMessage('');
    setSelectedTemplate(null);
    setAutocompleteKey(prevKey => prevKey + 1);
    setIsEditing(false);
  };

  const saveTemplate = async () => {
    try {
      if (isEditing && selectedTemplate) {
        await axios.patch(`${config.base_api_url}/template-message/${selectedTemplate.id}/`, {
          title,
          message,
        }, {
          headers: { Authorization: `Bearer ${auth.getToken()}` },
        });
      } else {
        await axios.post(`${config.base_api_url}/template-message/`, {
          title,
          message,
        }, {
          headers: { Authorization: `Bearer ${auth.getToken()}` },
        });
      }
      fetchTemplates();
      clearInputs();
    } catch (error) {
      console.error('Erro ao salvar template:', error);
    }
  };

  const deleteTemplate = async (id) => {
    try {
      await axios.delete(`${config.base_api_url}/template-message/${id}/`, {
        headers: { Authorization: `Bearer ${auth.getToken()}` },
      });
      fetchTemplates();
      clearInputs();
    } catch (error) {
      console.error('Erro ao deletar template:', error);
    }
  };

  const handleTemplateSelect = (event, value) => {
    setSelectedTemplate(value);
    if (!value) {
      clearInputs();
    }
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const renderMessage = (message) => {
    let parsedMessage;
    try {
      parsedMessage = JSON.parse(message);
    } catch (e) {
      parsedMessage = { body: message };
    }

    const { body, header, footer } = parsedMessage;

    let renderedMessage = body;

    const variableRegex = /(\{.*?\}|\[.*?\])/g;
    const variables = renderedMessage.match(variableRegex) || [];

    variables.forEach((variable, index) => {
      renderedMessage = renderedMessage.replace(variable, `__VAR${index}__`);
    });

    renderedMessage = renderedMessage
      .replace(/\*(.*?)\*/g, '<strong>$1</strong>') // bold
      .replace(/_(.*?)_/g, '<em>$1</em>') // italic
      .replace(/~(.*?)~/g, '<del>$1</del>') // strikethrough
      .replace(/`(.*?)`/g, '<code>$1</code>'); // monospace

    variables.forEach((variable, index) => {
      renderedMessage = renderedMessage.replace(`__VAR${index}__`, variable);
    });

    return { header: header?.text, body: renderedMessage, footer };
  };

  const handleInfoClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInfoClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Card style={{ padding: 10, borderRadius: 15 }} sx={{ boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)' }}>
            <MDBox
              mx={0.2}
              mt={-2.2}
              py={1}
              px={1}
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="info"
              mb={2}
            >
              <MDTypography variant="h6" color="white">
                Templates de WhatsApp
              </MDTypography>
            </MDBox>
            <Autocomplete
              key={autocompleteKey}
              disablePortal
              id="combo-box-demo"
              options={templates}
              getOptionLabel={(option) => `${option.tag} - ${option.is_template ? 'Aprovado' : 'Não Aprovado'}`}
              onChange={handleTemplateSelect}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Templates"
                  placeholder="Selecione um template"
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      height: 10,
                    }
                  }}
                />
              )}
              sx={{
                width: '100%'
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props} sx={{ marginBottom: '2px' }}>
                  {option.tag} -  {option.is_template ? 'Aprovado' : 'Não Aprovado'}
                </Box>
              )}
            />
            <Box sx={{ display: 'flex', gap: 1, mt: 2}}>
              <MDButton variant="contained" color="info" size="medium" fullWidth onClick={() => {
                if (selectedTemplate) {
                  setTitle(selectedTemplate.tag);
                  setMessage(selectedTemplate.text);
                  setIsEditing(true);
                }
              }}>Ver</MDButton>
              <MDButton variant="contained" color="error" size="medium" fullWidth onClick={() => {
                if (selectedTemplate) {
                  deleteTemplate(selectedTemplate.id);
                }
              }} disabled>Deletar</MDButton>
            </Box>
            <MDTypography variant="caption" color="textSecondary" sx={{ fontStyle: 'italic', fontSize: '0.8rem', mt: 1, fontWeight: "bold"}}>
              Variáveis Disponíveis
            </MDTypography>
            <MDTypography variant="caption" color="textSecondary" sx={{ fontStyle: 'italic', fontSize: '0.8rem', mb: 1 }}> 
            nome_cliente, boletos
            </MDTypography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <TextField
                label="Nome do Template"
                variant="outlined"
                fullWidth
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                disabled
              />
              <IconButton aria-describedby={id} onClick={handleInfoClick} disabled>
                <InfoIcon />
              </IconButton>
              <Dialog
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleInfoClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Box sx={{ p: 2, bgcolor: 'white' }}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Estilizações de texto disponíveis<br /></strong>
                    *Negrito* - <strong>Negrito</strong><br />
                    _Itálico_ - <em>Itálico</em><br />
                    ~Tachado~- <del>Tachado</del><br />
                    `Monoespaçado` - <code>Monoespaçado</code>
                  </Typography>
                </Box>
              </Dialog>
            </Box>
            <TextField
              label="Mensagem"
              variant="filled"
              fullWidth
              required
              multiline
              sx={{ mt: 2 }}
              color="info"
              value={message}
              onChange={handleMessageChange}
              disabled
            />
            <MDButton 
              variant="contained" 
              color="success" 
              size="medium" 
              fullWidth 
              sx={{ mt: 2 }} 
              onClick={saveTemplate}
              disabled
            >
              Salvar
            </MDButton>
          </Card>
        </Grid>
        <Grid item xs={7}>
          <Card sx={{ minHeight: '650px' }}>
            <MDBox
              mx={1}
              mt={-1}
              py={1}
              px={1}
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Prévia da Mensagem de WhatsApp
              </MDTypography>
            </MDBox>
            <MDBox
              style={{
                backgroundColor: "#dcf8c6",
                borderTopLeftRadius: "15px",
                borderBottomRightRadius: "15px",
                borderBottomLeftRadius: "15px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                maxWidth: "90%",
                marginLeft: "auto",
                marginRight: "20px",
                marginTop: "10px",
                boxShadow: "0 2px 15px rgba(0, 0, 0, 0.3)",
                height: "auto",
                wordBreak: 'break-word',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '-10px',
                  right: '10px',
                  width: '0',
                  height: '0',
                  borderLeft: '10px solid #dcf8c6',
                  borderRight: '10px solid transparent',
                  borderTop: '10px solid #dcf8c6',
                  boxShadow: "0 2px 15px rgba(0, 0, 0, 0.1)"
                }}
              />
              <MDTypography variant="caption" color="textSecondary" sx={{ fontWeight: "bold"}}>
                Declatack Financeiro
              </MDTypography>
              <MDTypography variant="body1" color="textPrimary" style={{ whiteSpace: 'pre-wrap' }}>
                {selectedTemplate && selectedTemplate.text ? (
                  <>
                    {renderMessage(selectedTemplate.text).header && (
                      <div dangerouslySetInnerHTML={{ __html: renderMessage(selectedTemplate.text).header }} />
                    )}
                    <div dangerouslySetInnerHTML={{ __html: renderMessage(selectedTemplate.text).body }} />
                    {renderMessage(selectedTemplate.text).footer && (
                      <div dangerouslySetInnerHTML={{ __html: renderMessage(selectedTemplate.text).footer }} />
                    )}
                  </>
                ) : (
                  "Sua mensagem aparecerá aqui..."
                )}
              </MDTypography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                <MDTypography variant="caption" color="textSecondary">
                  {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </MDTypography>
                <MDTypography variant="caption" color="textSecondary">
                  ✓
                </MDTypography>
              </Box>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <Dialog open={alertOpen} onClose={() => setAlertOpen(false)}>
        <DialogTitle>{alertTitle}</DialogTitle>
        <DialogContent>{alertMessage}</DialogContent>
        <DialogActions>
          <MDButton onClick={() => setAlertOpen(false)} color="info" fullWidth>Fechar</MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default WhatsAppTemplate;