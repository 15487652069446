import React from 'react';
import { Grid, Card, TextField, Box, Typography, IconButton, Dialog } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import config from 'layouts/utils/config';
import auth from 'layouts/utils/auth';
import InfoIcon from '@mui/icons-material/Info';

function EmailTemplate() {
  const [message, setMessage] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [templates, setTemplates] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [autocompleteKey, setAutocompleteKey] = React.useState(0);
  const [isEditing, setIsEditing] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${config.base_api_url}/template-message/?type=email`, {
        headers: { Authorization: `Bearer ${auth.getToken()}` },
      });
      setTemplates(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const clearInputs = () => {
    setTitle('');
    setMessage('');
    setSelectedTemplate(null);
    setAutocompleteKey(prevKey => prevKey + 1);
    setIsEditing(false);
  };

  const saveTemplate = async () => {
    try {
      if (isEditing && selectedTemplate) {
        await axios.patch(`${config.base_api_url}/template-message/${selectedTemplate.id}/`, {
          title,
          message,
          type: 'email',
        }, {
          headers: { Authorization: `Bearer ${auth.getToken()}` },
        });
      } else {
        await axios.post(`${config.base_api_url}/template-message/`, {
          title,
          message,
          type: 'email',
        }, {
          headers: { Authorization: `Bearer ${auth.getToken()}` },
        });
      }
      fetchTemplates();
      clearInputs();
    } catch (error) {
      console.error('Erro ao salvar template:', error);
    }
  };

  const deleteTemplate = async (id) => {
    try {
      await axios.delete(`${config.base_api_url}/template-message/${id}/`, {
        headers: { Authorization: `Bearer ${auth.getToken()}` },
      });
      fetchTemplates();
      clearInputs();
    } catch (error) {
      console.error('Erro ao deletar template:', error);
    }
  };

  const handleTemplateSelect = (event, value) => {
    setSelectedTemplate(value);
    if (!value) {
      clearInputs();
    }
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const renderizarMensagem = (mensagem) => {
    let mensagemRenderizada = mensagem;

    const regexVariavel = /\{.*?\}/g;
    const variaveis = mensagemRenderizada.match(regexVariavel) || [];

    variaveis.forEach((variavel, index) => {
      mensagemRenderizada = mensagemRenderizada.replace(variavel, `__VAR${index}__`);
    });

    mensagemRenderizada = mensagemRenderizada
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // negrito
      .replace(/\*(.*?)\*/g, '<em>$1</em>') // itálico
      .replace(/~(.*?)~/g, '<del>$1</del>') // tachado
      .replace(/`(.*?)`/g, '<code>$1</code>'); // monoespaçado

    variaveis.forEach((variavel, index) => {
      mensagemRenderizada = mensagemRenderizada.replace(`__VAR${index}__`, variavel);
    });

    return mensagemRenderizada;
  };

  const handleInfoClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInfoClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Card style={{ padding: 10, borderRadius: 15 }} sx={{ boxShadow: '0 4px 6</Grid>px -1px rgba(0, 0, 0, 0.1)' }}>
          <MDBox
            mx={0.2}
            mt={-2.2}
            py={1}
            px={1}
            variant="gradient"
            bgColor="warning"
            borderRadius="lg"
            coloredShadow="info"
            mb={2}
          >
            <MDTypography variant="h6" color="white">
              Templates de Email
            </MDTypography>
          </MDBox>
          <Autocomplete
            key={autocompleteKey}
            disablePortal
            id="combo-box-demo"
            options={templates}
            getOptionLabel={(option) => option.title}
            onChange={handleTemplateSelect}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Templates"
                placeholder="Selecione um template"
                sx={{
                  '& .MuiOutlinedInput-input': {
                    height: 10,
                  }
                }}
              />
            )}
            sx={{
              width: '100%'
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} sx={{ marginBottom: '2px' }}>
                {option.title}
              </Box>
            )}
          />
          <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
            <MDButton variant="contained" color="info" size="medium" fullWidth onClick={() => {
              if (selectedTemplate) {
                setTitle(selectedTemplate.title);
                setMessage(selectedTemplate.message);
                setIsEditing(true);
              }
            }}>Editar</MDButton>
            <MDButton variant="contained" color="error" size="medium" fullWidth onClick={() => {
              if (selectedTemplate) {
                deleteTemplate(selectedTemplate.id);
              }
            }}>Deletar</MDButton>
          </Box>
          <MDTypography variant="caption" color="textSecondary" sx={{ fontStyle: 'italic', fontSize: '0.8rem', mt: 1, fontWeight: "bold" }}>
            Variáveis Disponíveis
          </MDTypography>
          <MDTypography variant="caption" color="textSecondary" sx={{ fontStyle: 'italic', fontSize: '0.8rem', mb: 1 }}>
            nome_cliente, boletos
          </MDTypography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <TextField
              label="Nome do Template"
              variant="outlined"
              fullWidth
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <IconButton aria-describedby={id} onClick={handleInfoClick}>
              <InfoIcon />
            </IconButton>
            <Dialog
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleInfoClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box sx={{ p: 2, bgcolor: 'white' }}>
                <MDTypography variant="body2" color="textSecondary">
                  <strong>Estilizações de texto disponíveis<br /></strong>
                  **Negrito** - <strong>Negrito</strong><br />
                  *Itálico* - <em>Itálico</em><br />
                  ~Tachado~- <del>Tachado</del><br />
                  `Monoespaçado` - <code>Monoespaçado</code>
                </MDTypography>
              </Box>
            </Dialog>
          </Box>
          <TextField
            label="Mensagem"
            variant="filled"
            fullWidth
            required
            multiline
            sx={{ mt: 2 }}
            color="info"
            value={message}
            onChange={handleMessageChange}
          />
          <MDButton 
            variant="contained" 
            color="success" 
            size="medium" 
            fullWidth 
            sx={{ mt: 2 }} 
            onClick={saveTemplate}
            disabled={!title || !message}
          >
            Salvar
          </MDButton>
        </Card>
      </Grid>
      <Grid item xs={7}>
        <Card sx={{ minHeight: '650px' }}>
          <MDBox
            mx={1}
            mt={-1}
            py={1}
            px={1}
            variant="gradient"
            bgColor="warning"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Prévia da Mensagem de Email
            </MDTypography>
          </MDBox>
          <MDBox
            sx={{
              backgroundColor: "#f0f0f0",
              padding: 2,
              margin: 2,
              boxShadow: "0 2px 15px rgba(0, 0, 0, 0.3)",
            }}
          >
            <MDTypography
              variant="h6"
              color="textPrimary"
              sx={{ fontWeight: "bold", marginBottom: 1 }}
            >
              financeiro@declatrack.com.br
            </MDTypography>
            <MDTypography
              variant="body1"
              color="textSecondary"
              style={{ whiteSpace: 'pre-wrap', padding: '10px', borderRadius: 2 }}
            >
              <span dangerouslySetInnerHTML={{ __html: renderizarMensagem(message) || "Sua mensagem aparecerá aqui..." }} />
            </MDTypography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
              <MDTypography variant="caption" color="textSecondary">
                {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </MDTypography>
              <MDTypography variant="caption" color="textSecondary">
                ✉️
              </MDTypography>
            </Box>
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
}

export default EmailTemplate;
