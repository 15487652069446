import { isEmpty } from 'lodash';
import axios from 'axios';

const TOKEN_KEY = '__declatrack__access_token';
const USER_INFO = '__declatrack__me';

const parse = JSON.parse;
const stringify = JSON.stringify;

const auth = {
    clear(key) {
        if (localStorage && localStorage.getItem(key)) {
            return localStorage.removeItem(key);
        }

        if (sessionStorage && sessionStorage.getItem(key)) {
            return sessionStorage.removeItem(key);
        }

        return null;
    },

    clearAppStorage() {
        if (localStorage) {
            localStorage.clear();
        }

        if (sessionStorage) {
            sessionStorage.clear();
        }
    },

    clearToken(tokenKey = TOKEN_KEY) {
        return auth.clear(tokenKey);
    },

    clearUserInfo(userInfo = USER_INFO) {
        return auth.clear(userInfo);
    },

    get(key) {
        if (localStorage && localStorage.getItem(key)) {
            return parse(localStorage.getItem(key)) || null;
        }

        if (sessionStorage && sessionStorage.getItem(key)) {
            return parse(sessionStorage.getItem(key)) || null;
        }

        return null;
    },

    getToken(tokenKey = TOKEN_KEY) {
        return auth.get(tokenKey);
    },

    getUserInfo(userInfo = USER_INFO) {
        return auth.get(userInfo);
    },

    set(value, key, isSessionStorage) {
        if (isEmpty(value)) {
            return null;
        }

        if (isSessionStorage && sessionStorage) {
            return sessionStorage.setItem(key, stringify(value));
        }

        if (!isSessionStorage && localStorage) {
            return localStorage.setItem(key, stringify(value));
        }

        return null;
    },

    setToken(value = '', isSessionStorage = false, tokenKey = TOKEN_KEY) {
        return auth.set(value, tokenKey, isSessionStorage);
    },

    setUserInfo(value = '', isSessionStorage = false, userInfo = USER_INFO) {
        return auth.set(value, userInfo, isSessionStorage);
    },
};

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            auth.clearAppStorage();
            window.location.href = '/entrar';
        }
        return Promise.reject(error);
    }
);

export default auth;