import React from "react";
import auth from "layouts/utils/auth";
import config from "layouts/utils/config";
import { Grid, Card, TextField, Box, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, IconButton } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';

function ApiConfig () {
    const [user, setUser] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [codeClient, setCodeClient] = React.useState('');
    const [baseUrl, setBaseUrl] = React.useState('');
    const [apiKey, setApiKey] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertTitle, setAlertTitle] = React.useState('');

    const getApiConfig = async () => {
        try {
            const response = await axios.get(`${config.base_api_url}/settings/api/`, {
                headers: { Authorization: `Bearer ${auth.getToken()}` },
            });
            setUser(response.data.API_USER || '');
            setPassword(response.data.API_USER_PASSWORD || '');
            setCodeClient(response.data.API_CODE_CLIENT || '');
            setBaseUrl(response.data.API_BASE_URL || '');
            setApiKey(response.data.API_KEY || '');
        } catch (error) {
            console.error(error);
        }
    }

    React.useEffect(() => {
        getApiConfig();
    }, []);

    const updateApiConfig = async () => {
        try {
            await axios.patch(`${config.base_api_url}/settings/api/`, {
                API_USER: user,
                API_USER_PASSWORD: password,
                API_CODE_CLIENT: codeClient,
                API_BASE_URL: baseUrl,
                API_KEY: apiKey,
            }, {
                headers: { Authorization: `Bearer ${auth.getToken()}` },
            });
            setAlertTitle("Sucesso");
            setAlertMessage("Configurações de API salvas com sucesso.");
            setAlertOpen(true);
            getApiConfig();
        } catch (error) {
            console.error(error);
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card style={{ padding: 10, borderRadius: 15 }} sx={{ boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)' }}>
                    <MDBox
                        mx={0.2}
                        mt={-2.2}
                        py={1}
                        px={1}
                        variant="gradient"
                        bgColor="warning"
                        borderRadius="lg"
                        coloredShadow="info"
                        mb={2}
                    >
                        <MDTypography variant="h6" color="white">
                        Configurações de API
                        </MDTypography>
                    </MDBox>
                    <Box sx={{ marginBottom: '20px' }}>
                        <TextField
                            fullWidth
                            label="Usuário"
                            value={user}
                            onChange={(e) => setUser(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '20px' }}>
                        <TextField
                            fullWidth
                            label="Senha"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '20px' }}>
                        <TextField
                            fullWidth
                            label="Código do Cliente"
                            value={codeClient}
                            onChange={(e) => setCodeClient(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '20px' }}>
                        <TextField
                            fullWidth
                            label="URL Base da API"
                            value={baseUrl}
                            onChange={(e) => setBaseUrl(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ marginBottom: '20px' }}>
                        <TextField
                            fullWidth
                            label="Chave da API"
                            value={apiKey}
                            onChange={(e) => setApiKey(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ marginTop: '20px' }}>
                        <MDButton
                            variant="contained"
                            color="info"
                            onClick={updateApiConfig}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Salvando...' : 'Salvar'}
                        </MDButton>
                    </Box>
                </Card>
            </Grid>
            <Dialog open={alertOpen} onClose={() => setAlertOpen(false)}>
                <DialogTitle>{alertTitle}</DialogTitle>
                <DialogContent>{alertMessage}</DialogContent>
                <DialogActions>
                    <MDButton onClick={() => setAlertOpen(false)} color="info" fullWidth>Fechar</MDButton>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default ApiConfig;