import React from "react";
import ApiConfig from "./tabs/ApiConfig";
import SmtpConfig from "./tabs/SmtpConfig";
import PoliConfig from "./tabs/PoliConfig";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import './styles.css';

function Configuracoes() {
    const [activeTab, setActiveTab] = React.useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <DashboardLayout>
            <MDBox>
                <div className="tab-header">
                    <div
                        onClick={() => handleTabClick(0)}
                        className={activeTab === 0? 'active' : ''}
                    >
                        API
                    </div>
                    <div 
                        onClick={() => handleTabClick(1)}
                        className={activeTab === 1? 'active' : ''}
                    >
                        SMTP
                    </div>
                    <div
                        onClick={() => handleTabClick(2)}
                        className={activeTab === 2? 'active' : ''}
                    >
                        POLI CHAT
                    </div>
                </div>
                <div className={`tab-content ${activeTab === 0? 'active' : ''}`}>
                    <ApiConfig />
                </div>
                <div className={`tab-content ${activeTab === 1? 'active' : ''}`}>
                    <SmtpConfig />
                </div>
                <div className={`tab-content ${activeTab === 2? 'active' : ''}`}>
                    <PoliConfig />
                </div>
            </MDBox>
        </DashboardLayout>
    )
}

export default Configuracoes;