import React from 'react';
import { Grid, Card, TextField, Checkbox, IconButton, Box, Chip, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import SyncIcon from '@mui/icons-material/Sync';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import boxShadow from 'assets/theme/functions/boxShadow';

function FilterTickets({
    clientsData,
    autocompleteValue,
    handleAutocompleteChange,
    handleFilterDialogOpen,
    syncCustomers,
    isLoadingCustomers,
    isSyncCustomers,
    handleDateChange,
    payloadFilter,
    ticketsDataTableRows,
    isLoading
}) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card style={{ padding: 10, borderRadius: 15 }} sx={{ boxShadow: boxShadow([0, 4], [6, -1], 'rgba(0, 0, 0, 0.1)', 0.1) }}>
                    <MDBox
                        mx={0.2}
                        mt={-2.2}
                        py={1}
                        px={1}
                        variant="gradient"
                        bgColor="warning"
                        borderRadius="lg"
                        coloredShadow="info"
                        mb={2}
                    >
                        <MDTypography variant="h6" color="white">
                            Filtro de Clientes
                        </MDTypography>
                    </MDBox>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={10} mb={1}>
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                limitTags={3}
                                options={isLoadingCustomers ? [ 
                                    { id: 'selectAll', nome_cliente: 'Carregando...' },
                                ] : [ 
                                    { id: 'selectAll', nome_cliente: 'Selecionar Todos' },
                                    ...clientsData,
                                ]}
                                getOptionLabel={(option) => option.nome_cliente}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.id}>
                                        {
                                            option.nome_cliente === 'Carregando...' ? 
                                                <div className="loading-bars">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div> : 
                                            option.nome_cliente
                                        }
                                    </Box>
                                    )
                                }
                                value={autocompleteValue}
                                onChange={(event, value) => {
                                    if (value.some(v => v.id === 'selectAll')) {
                                        if (autocompleteValue.length === clientsData.length + 2) {
                                            handleAutocompleteChange(event, []);
                                        } else {
                                            const newClients = clientsData.filter(client => 
                                                !autocompleteValue.some(existingClient => existingClient.cpf_cliente === client.cpf_cliente)
                                            );
                                            handleAutocompleteChange(event, [...autocompleteValue, ...newClients]);
                                        }
                                        return;
                                    }
                                    handleAutocompleteChange(event, value.filter(v => v.id !== 'previous' && v.id !== 'next'));
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Clientes"
                                        placeholder="Busca por Cliente"
                                        sx={{
                                            '& .MuiOutlinedInput-input': {
                                                height: 10,
                                            }
                                        }}
                                    />
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.filter(v => v.id !== 'previous' && v.id !== 'next').map((option, index) => (
                                        <Chip
                                            variant="outlined"
                                            label={option.nome_cliente}
                                            {...getTagProps({ index })}
                                            key={option.id}
                                            style={{ backgroundColor: '#00647E', borderColor: '#00647E' }}
                                        />
                                    ))
                                }
                                sx={{
                                    '& .MuiAutocomplete-tag': {
                                        backgroundColor: '#00647E',
                                        borderColor: '#00647E',
                                        color: '#FFFFFF',
                                    },
                                }}
                                isOptionEqualToValue={(option, value) => option.cpf_cliente === value.cpf_cliente}
                            />
                        </Grid>
                        <IconButton onClick={syncCustomers}>
                            <SyncIcon />
                        </IconButton>
                        <Grid item xs={8} md={1.6}>
                            <MDButton variant="contained" color="info" size="medium" fullWidth onClick={handleFilterDialogOpen}>Filtro</MDButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <DatePicker
                                label="Data de Emissão Inicial"
                                value={payloadFilter.data_emissao_inicial || null}
                                onChange={(date) => handleDateChange('data_emissao_inicial', date)}
                                renderInput={(params) => <TextField {...params} style={{width: "100%", marginTop: "5px"}} />}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                label="Data de Emissão Final"
                                value={payloadFilter.data_emissao_final || null}
                                onChange={(date) => handleDateChange('data_emissao_final', date)}
                                renderInput={(params) => <TextField {...params} style={{width: "100%", marginTop: "5px"}} />}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                label="Data de Vencimento Inicial"
                                value={payloadFilter.data_vencimento_inicial || null}
                                onChange={(date) => handleDateChange('data_vencimento_inicial', date)}
                                renderInput={(params) => <TextField {...params} style={{width: "100%", marginTop: "5px"}} />}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                label="Data de Vencimento Final"
                                value={payloadFilter.data_vencimento_final || null}
                                onChange={(date) => handleDateChange('data_vencimento_final', date)}
                                renderInput={(params) => <TextField {...params} style={{width: "100%", marginTop: "5px"}} />}
                            />
                        </Grid>
                    </Grid>
                    <MDButton 
                        variant="contained" 
                        color="info" 
                        size="medium" 
                        fullWidth sx={{ mt: 2, mb: 2 }} 
                        onClick={ticketsDataTableRows}
                        disabled={isLoading}
                    >
                        Buscar
                    </MDButton>
                </Card>
            </Grid>
        </Grid>
    );
}

export default FilterTickets;
