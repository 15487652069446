import React, { useState } from 'react';
import WhatsAppTemplate from './tabs/WhatsappTemplate';
import EmailTemplate from './tabs/EmailTemplate';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import './styles.css';

function Template() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <DashboardLayout>
      <MDBox>
        <div className="tab-header">
          <div
            onClick={() => handleTabClick(0)}
            className={activeTab === 0 ? 'active' : ''}
          >
            WhatsApp
          </div>
          <div
            onClick={() => handleTabClick(1)}
            className={activeTab === 1 ? 'active' : ''}
          >
            Email
          </div>
        </div>
        <div className={`tab-content ${activeTab === 0 ? 'active' : ''}`}>
          <WhatsAppTemplate />
        </div>
        <div className={`tab-content ${activeTab === 1 ? 'active' : ''}`}>
          <EmailTemplate />
        </div>
      </MDBox>
    </DashboardLayout>
  );
}

export default Template;
