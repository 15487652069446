import Notificar from "layouts/notificar";
import Template from "layouts/mensagemTemplate";
import Relatorio from "layouts/relatorio";
import Icon from "@mui/material/Icon";
import Configuracoes from "layouts/configuracao";

const routes = [
  {
    type: "collapse",
    name: "Notificar",
    key: "notificar",
    icon: <Icon fontSize="small">phone</Icon>,
    route: "/notificar",
    component: <Notificar />,
  },
  {
    type: "collapse",
    name: "Relatório",
    key: "relatorio",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/relatorio",
    component: <Relatorio />,
  },
  {
    type: "collapse",
    name: "Template",
    key: "template",
    icon: <Icon fontSize="small">message</Icon>,
    route: "/template",
    component: <Template />,
  },
  {
    type: "collapse",
    name: "Configurações",
    key: "configuracoes",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/configuracoes",
    component: <Configuracoes />,
  }
];

export default routes;
