import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import auth from "layouts/utils/auth";
import config from "layouts/utils/config";
import './index.css';

function Basic() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${config.base_api_url}/api/token/`, { username: email, password });
      const { access, refresh } = response.data;
      auth.setToken(access, rememberMe);
      auth.setToken(refresh, rememberMe, "__declatrack__refresh_token");
      navigate("/notificar");
    } catch (error) {
      console.error("Erro >> ", error);
      setErrorMessage("Falha ao autenticar.");
      setShowError(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  useEffect(() => {
    if (showError) {
      const timer = setTimeout(() => {
        setShowError(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showError]);

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor={showError ? "error" : "warning"}
          borderRadius="lg"
          coloredShadow={showError ? "error" : "warning"}
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {showError ? errorMessage : "Declatrack - Notificações"}
          </MDTypography>
          {showError && (
            <MDBox
              mt={1}
              height="4px"
              width="100%"
              borderRadius="lg"
              bgColor="white"
              sx={{ animation: "progress 5.1s linear" }}
            />
          )}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Usuário"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={1}>
              <MDInput
                type="password"
                label="Senha"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </MDBox>
            <MDBox mb={1} ml={0.5}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={handleSetRememberMe}
                    color="primary"
                  />
                }
                label="Permanecer conectado"
              />
            </MDBox>
            <MDBox mt={1} mb={1}>
              <MDButton onClick={handleLogin} variant="gradient" color="info" fullWidth>
                Entrar
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                <a href="https://www.declatrack.com.br" target="_blank" rel="noreferrer">
                  declatrack.com.br
                </a>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;